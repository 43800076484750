import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, analytics } from '../../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { logEvent } from "firebase/analytics";
import Modal from 'react-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import SEO from '../../components/SEO';
import { X } from 'lucide-react';

const stripePromise = loadStripe('pk_live_51Q9YadHTjZFGR8VK4Q4pxgbmC2fZOjiYDcYPfPQoiDGQtC4L2WCECHFFpvlddIlMpUHig3NjseCW0tT5aqveAGHu00MM9h1Zup');

Modal.setAppElement('#root');

const carouselItems = [
  {
    imageUrl: 'https://pbs.twimg.com/media/Es0NOvSXEAAXfqi.jpg',
    title: 'Unlimited Real-Time Translations',
    description: 'Access real-time transcriptions and translations in over 100 languages.',
  },
  {
    imageUrl: 'https://thumbs.dreamstime.com/b/translation-service-isolated-cartoon-vector-illustrations-professional-synchronic-translators-work-freelance-job-small-business-253366003.jpg',
    title: 'Have Conversations in Real Time in 100 languages',
    description: 'Speak to anyone, anywhere using our real time conversation ability.',
  },
  {
    imageUrl: 'https://mir-s3-cdn-cf.behance.net/project_modules/hd/7964c0108300933.5fbb01b5f06cd.png',
    title: 'Save to Phrasebook',
    description: 'Save your translations to a personalized phrasebook for easy reference.',
  },
];

function CheckoutScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const isDismissible = location.state?.isDismissible;
  const returnUrl = location.state?.returnUrl;

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      screen_name: 'checkout_screen',
      screen_class: 'CheckoutScreen',
      is_dismissible: isDismissible
    });

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        logEvent(analytics, 'user_data', {
          user_id: currentUser.uid,
          user_email_domain: currentUser.email?.split('@')[1],
          is_new_user: currentUser.metadata.creationTime === currentUser.metadata.lastSignInTime
        });

        if (selectedPlan) {
          setIsModalOpen(false);
          initiateCheckout();
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth, selectedPlan]);

  const handleDismiss = () => {
    // Make sure hasSeenInitialCheckout is set in localStorage before dismissing
    localStorage.setItem('hasSeenInitialCheckout', 'true');
    
    logEvent(analytics, 'checkout_dismissed', {
      user_id: user?.uid || 'anonymous',
      selected_plan: selectedPlan,
      return_url: returnUrl
    });
  
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      navigate('/');
    }
  };

  const handleScroll = (e) => {
    const element = e.target;
    const scrollPosition = element.scrollLeft;
    const slideWidth = element.offsetWidth;
    const currentIndex = Math.round(scrollPosition / slideWidth);
    
    if (currentIndex !== activeIndex) {
      setActiveIndex(currentIndex);
      logEvent(analytics, 'carousel_slide_view', {
        slide_index: currentIndex,
        slide_title: carouselItems[currentIndex].title
      });
    }
  };

  const scrollToSlide = (index) => {
    const carousel = document.querySelector('.carousel-container');
    if (carousel) {
      carousel.scrollTo({
        left: index * carousel.offsetWidth,
        behavior: 'smooth'
      });
    }
    setActiveIndex(index);
    
    logEvent(analytics, 'carousel_dot_click', {
      slide_index: index,
      slide_title: carouselItems[index].title
    });
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    logEvent(analytics, 'select_plan', {
      plan_type: plan,
      user_id: user?.uid || 'anonymous',
      price: plan === 'annual' ? 49.99 : 2.99,
      currency: 'USD'
    });
  };

  const handlePurchase = () => {
    if (!selectedPlan) return;

    logEvent(analytics, 'checkout_start', {
      plan_type: selectedPlan,
      user_id: user?.uid || 'anonymous',
      price: selectedPlan === 'annual' ? 49.99 : 2.99,
      currency: 'USD',
      is_authenticated: !!user
    });

    if (!user) {
      setIsModalOpen(true);
      logEvent(analytics, 'auth_prompt', {
        trigger: 'checkout_attempt',
        plan_type: selectedPlan
      });
      return;
    }

    initiateCheckout();
  };

  const initiateCheckout = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://um26wsvb68.execute-api.us-east-2.amazonaws.com/prod/createCheckout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          plan: selectedPlan, 
          customerId: user.uid, 
          email: user.email,
          returnUrl: returnUrl || window.location.origin // Include return URL
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { sessionId } = await response.json();
      const stripe = await stripePromise;

      logEvent(analytics, 'checkout_progress', {
        step: 'redirect_to_stripe',
        plan_type: selectedPlan,
        user_id: user.uid,
        session_id: sessionId
      });

      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      logEvent(analytics, 'checkout_error', {
        error_message: error.message,
        plan_type: selectedPlan,
        user_id: user?.uid
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async () => {
    try {
      logEvent(analytics, 'login_attempt', {
        method: 'google',
        context: 'checkout'
      });

      const result = await signInWithPopup(auth, provider);
      
      logEvent(analytics, 'login_success', {
        method: 'google',
        user_id: result.user.uid,
        is_new_user: result.user.metadata.creationTime === result.user.metadata.lastSignInTime
      });
    } catch (error) {
      console.error("Error signing in with Google", error);
      logEvent(analytics, 'login_error', {
        error_code: error.code,
        error_message: error.message,
        method: 'google'
      });
    }
  };

  // Create schema for structured data
  const checkoutSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "RealTranslate Premium Subscription",
    "description": "Upgrade to RealTranslate Premium for unlimited translations, faster processing, and more features."
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Premium Subscription | RealTranslate"
        description="Upgrade to RealTranslate Premium for unlimited translations, faster processing, and more features."
        canonicalUrl="/checkout"
        schema={checkoutSchema}
      />
      
      <div className="max-w-lg mx-auto px-4 py-8 bg-white min-h-screen">
        {/* Dismiss button */}
        {isDismissible && (
          <div className="mb-8">
            <button
              onClick={handleDismiss}
              className="text-gray-600 hover:text-gray-800 flex items-center gap-2"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path 
                  fillRule="evenodd" 
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" 
                  clipRule="evenodd" 
                />
              </svg>
              Back to Translator
            </button>
          </div>
        )}

        {/* Carousel Section */}
        <div className="mb-12 overflow-hidden">
          <div 
            className="carousel-container flex overflow-x-auto snap-x snap-mandatory no-scrollbar"
            onScroll={handleScroll}
          >
            {carouselItems.map((item, index) => (
              <div key={index} className="w-full flex-none snap-center px-6">
                <img 
                  src={item.imageUrl} 
                  alt={item.title}
                  className="w-full h-72 object-contain mb-8 rounded-2xl"
                />
                <h3 className="text-2xl font-semibold text-center mb-3 text-gray-800">
                  {item.title}
                </h3>
                <p className="text-gray-600 text-center text-lg leading-relaxed">
                  {item.description}
                </p>
              </div>
            ))}
          </div>

          {/* Carousel Navigation */}
          <div className="flex justify-center space-x-3 mt-8">
            {carouselItems.map((_, index) => (
              <button
                key={index}
                onClick={() => scrollToSlide(index)}
                className={`w-2.5 h-2.5 rounded-full transition-all duration-300 ${
                  activeIndex === index 
                    ? 'bg-blue-600 w-4' 
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>

        {/* Pro Access Section */}
        <div className="mt-12">
          <h2 className="text-4xl font-bold text-blue-600 text-center mb-4">
            Unlock Pro Access
          </h2>
          <p className="text-center text-gray-600 text-lg mb-10">
            Enjoy unlimited access to all features and exclusive content with our Pro version!
          </p>
          
          {/* Subscription Plans */}
          <div className="space-y-4">
            <button
              onClick={() => handleSelectPlan('annual')}
              className={`w-full p-5 rounded-2xl border-2 transition-all duration-200 ${
                selectedPlan === 'annual' 
                  ? 'border-blue-600 bg-blue-50 shadow-lg transform scale-[1.02]' 
                  : 'border-gray-200 hover:border-blue-300 hover:bg-gray-50'
              }`}
            >
              <div className="flex justify-between items-center">
                <span className="text-2xl font-bold text-blue-600">Annual</span>
                <span className="bg-blue-600 text-white text-sm font-semibold px-4 py-1.5 rounded-full shadow-sm">
                  68% OFF
                </span>
              </div>
              <p className="text-gray-600 mt-2 text-lg">
                Unlimited and Full access for just $49.99
              </p>
            </button>

            <button
              onClick={() => handleSelectPlan('weekly')}
              className={`w-full p-5 rounded-2xl border-2 transition-all duration-200 ${
                selectedPlan === 'weekly' 
                  ? 'border-blue-600 bg-blue-50 shadow-lg transform scale-[1.02]' 
                  : 'border-gray-200 hover:border-blue-300 hover:bg-gray-50'
              }`}
            >
              <span className="text-2xl font-bold text-blue-600">Weekly</span>
              <p className="text-gray-600 mt-2 text-lg">
                Unlimited and Full access for just $2.99
              </p>
            </button>

            <div className="flex flex-col gap-4 mt-8">
              <button
                onClick={handlePurchase}
                disabled={!selectedPlan || loading}
                className="w-full py-4 bg-blue-600 text-white text-lg font-semibold rounded-2xl 
                  shadow-lg transition-all duration-200 hover:bg-blue-700 transform hover:scale-[1.02]
                  disabled:bg-gray-400 disabled:cursor-not-allowed disabled:transform-none"
              >
                {loading ? 'Processing...' : 'Continue'}
              </button>

              {isDismissible && (
                <button
                  onClick={handleDismiss}
                  className="w-full py-4 text-gray-600 text-lg font-semibold rounded-2xl 
                    border-2 border-gray-200 transition-all duration-200 hover:bg-gray-50"
                >
                  Maybe Later
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Sign In Modal */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Sign In Required"
          className="fixed inset-0 flex items-center justify-center p-4"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
        >
          <div className="bg-white rounded-3xl shadow-2xl overflow-hidden max-w-md w-full mx-auto animate-fade-in">
            <div className="relative">
              <div className="absolute top-4 right-4 z-10">
                <button 
                  onClick={() => setIsModalOpen(false)}
                  className="p-2 rounded-full bg-white/80 hover:bg-white transition-colors shadow-md"
                >
                  <X size={20} className="text-gray-600" />
                </button>
              </div>
              
              <div className="h-32 bg-gradient-to-r from-blue-500 to-indigo-600"></div>
            </div>
            
            <div className="px-8 py-6">
              <div className="text-center mb-8">
                <div className="inline-block p-4 bg-blue-50 rounded-full mb-4 -mt-16 border-4 border-white">
                  <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.98145 18.6913C6.54639 17.5806 7.40768 16.6478 8.46997 15.9963C9.53226 15.3448 10.7541 15 12.0003 15C13.2464 15 14.4683 15.3448 15.5306 15.9963C16.5929 16.6478 17.4542 17.5806 18.0191 18.6913" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-2">Sign In to Continue</h2>
                <p className="text-gray-500">Sign in to complete your purchase and unlock premium features</p>
              </div>
              
              <div className="space-y-4">
                <button
                  onClick={handleSignIn}
                  className="w-full py-3 px-4 bg-white border border-gray-300 rounded-xl font-medium
                    transition-all duration-300 hover:bg-gray-50 hover:shadow-md
                    disabled:bg-gray-100 disabled:cursor-not-allowed flex items-center justify-center"
                >
                  <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                  </svg>
                  Continue with Google
                </button>
                
                <div className="relative my-6">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                  </div>
                </div>
                
                <button
                  onClick={() => {/* Add Apple sign in logic */}}
                  className="w-full py-3 px-4 bg-black text-white rounded-xl font-medium
                    transition-all duration-300 hover:bg-gray-900 hover:shadow-md flex items-center justify-center"
                >
                  <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.05 20.28c-.98.95-2.05.02-3.18.02-1.14 0-2.22.91-3.18-.02-2.26.24-3.11-1.01-3.11-1.01-1.68-2.44-1.36-5.68-1.36-5.68C3.23 13.21 3 11.45 3 11.45c-.5-4.15 2.92-6.2 2.92-6.2 1.03-.7 2.72-1 2.72-1 1.14-.13 2.36.01 2.36.01l.01-.5c0-.5 1.09-.5 1.09-.5l.01.5c.7-.09 2.36-.01 2.36-.01s1.69.3 2.72 1c0 0 3.42 2.05 2.92 6.2 0 0-.23 1.76-3.22 2.14 0 0 .32 3.24-1.36 5.68 0 0-.85 1.25-3.11 1.01z"/>
                  </svg>
                  Continue with Apple
                </button>
              </div>
              
              <div className="mt-8 text-center">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-gray-500 hover:text-gray-700 text-sm font-medium"
                >
                  Maybe later
                </button>
              </div>
            </div>
            
            <div className="px-8 py-6 bg-gray-50 border-t border-gray-100 text-center">
              <p className="text-sm text-gray-600">
                By continuing, you agree to our 
                <a href="/terms" className="text-blue-600 hover:underline ml-1">Terms of Service</a> and 
                <a href="/privacy" className="text-blue-600 hover:underline ml-1">Privacy Policy</a>.
              </p>
            </div>
          </div>
        </Modal>

        <style jsx global>{`
          .no-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .no-scrollbar::-webkit-scrollbar {display: none;
          }
        `}</style>
      </div>
    </div>
  );
}

export default CheckoutScreen;