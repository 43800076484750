import React, { useState, useRef, useEffect, useContext } from 'react';
import { Upload, Camera, Image as ImageIcon, Loader, ChevronDown, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { SubscriptionContext } from '../context/SubscriptionContext';
import SEO from '../components/SEO';

const API_ENDPOINT = 'https://me25gad3gc.execute-api.us-east-2.amazonaws.com/prod/vision';

function ImageTranslation() {
  const [imagePreview, setImagePreview] = useState(null);
  const [translatedText, setTranslatedText] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const [fromLanguage, setFromLanguage] = useState({ id: 'en', name: 'English' });
  const [toLanguage, setToLanguage] = useState({ id: 'fr', name: 'French' });
  const [showFromSelector, setShowFromSelector] = useState(false);
  const [showToSelector, setShowToSelector] = useState(false);
  const [translationCount, setTranslationCount] = useState(localStorage.getItem('translationCount') || 0);
  const [hasRedirected, setHasRedirected] = useState(localStorage.getItem('hasRedirected') === 'true');
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const { isSubscribed } = useContext(SubscriptionContext);

  // Add state for mobile detection
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  // Add resize listener for responsive behavior
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Update translation count in local storage whenever it changes
    localStorage.setItem('translationCount', translationCount);
    localStorage.setItem('hasRedirected', hasRedirected);
  }, [translationCount, hasRedirected]);

  const handleImageUpload = (e) => {
    if (shouldRedirectToCheckout()) {
      navigate('/checkout');
      return;
    }
    
    const file = e.target.files[0];
    if (file) {
      // Validate file size
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        alert('Image size should be less than 5MB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        // Compress image if needed before sending to API
        sendImageToAPI(reader.result.split(',')[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCameraCapture = () => {
    if (shouldRedirectToCheckout()) {
      navigate('/checkout');
      return;
    }

    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((err) => {
        console.error('Error accessing the camera:', err);
      });
  };

  const capturePhoto = () => {
    if (shouldRedirectToCheckout()) {
      navigate('/checkout');
      return;
    }

    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, 640, 480);
    const imageDataUrl = canvasRef.current.toDataURL('image/jpeg');
    setImagePreview(imageDataUrl);
    sendImageToAPI(imageDataUrl.split(',')[1]);

    // Stop the video stream
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
  };

  const sendImageToAPI = async (base64Image) => {
    setIsTranslating(true);
    try {
      const payload = {
        image: base64Image,
        from_language: fromLanguage.name,
        to_language: toLanguage.name,
      };

      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Translation request failed');
      }

      const result = await response.json();
      setTranslatedText(result.translatedText);

      // Increment the translation count after a successful translation
      setTranslationCount((prevCount) => Number(prevCount) + 1);
    } catch (error) {
      console.error('Translation error:', error);
      alert('Failed to translate the text. Please try again.');
    } finally {
      setIsTranslating(false);
    }
  };

  const shouldRedirectToCheckout = () => {
    // If the user is not subscribed, has reached the free limit, and hasn't already been redirected
    if (!isSubscribed && translationCount >= 1 && !hasRedirected) {
      setHasRedirected(true);
      return true;
    }
    return false;
  };

  // Create schema for structured data
  const imageTranslationSchema = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "RealTranslate Image Translation",
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": `Translate text from images in ${fromLanguage?.name || 'any language'} to ${toLanguage?.name || 'any language'} for free with RealTranslate.`
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Image Translation | RealTranslate"
        description="Extract and translate text from images instantly. Upload an image or take a photo to translate text between 100+ languages."
        canonicalUrl="/image"
        schema={imageTranslationSchema}
      />
      
      <div className="max-w-4xl mx-auto p-4 md:p-6">
        <h1 className="text-2xl md:text-3xl font-bold text-center mb-6 text-blue-600 heading-responsive">Image Translation</h1>

        {/* Language selection bar */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mb-6">
          <div className="flex flex-col sm:flex-row justify-between gap-4">
            <div className="flex items-center space-x-2 sm:space-x-4">
              {/* Source language selector */}
              <div className="relative flex-1">
                <button
                  onClick={() => setShowFromSelector(!showFromSelector)}
                  className="w-full flex items-center justify-between space-x-1 px-3 py-2 border rounded-lg hover:bg-gray-50 transition-colors text-sm md:text-base touch-target"
                >
                  <span>From: <span className="font-medium">{fromLanguage.name}</span></span>
                  <ChevronDown size={16} />
                </button>
                {showFromSelector && (
                  <div className="absolute z-30 mt-1 w-screen max-w-[280px] sm:max-w-[320px]">
                    <LanguageSelector
                      title="Translate from"
                      selectedLanguage={fromLanguage}
                      onSelectLanguage={(lang) => {
                        setFromLanguage(lang);
                        setShowFromSelector(false);
                      }}
                      showDetect={true}
                    />
                  </div>
                )}
              </div>

              {/* Target language selector */}
              <div className="relative flex-1">
                <button
                  onClick={() => setShowToSelector(!showToSelector)}
                  className="w-full flex items-center justify-between space-x-1 px-3 py-2 border rounded-lg hover:bg-gray-50 transition-colors text-sm md:text-base touch-target"
                >
                  <span>To: <span className="font-medium">{toLanguage.name}</span></span>
                  <ChevronDown size={16} />
                </button>
                {showToSelector && (
                  <div className="absolute z-30 mt-1 w-screen max-w-[280px] sm:max-w-[320px] right-0">
                    <LanguageSelector
                      title="Translate to"
                      selectedLanguage={toLanguage}
                      onSelectLanguage={(lang) => {
                        setToLanguage(lang);
                        setShowToSelector(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          {/* Image upload area */}
          <div className="w-full md:w-1/2">
            {imagePreview ? (
              <div className="relative">
                <div className="w-full h-64 md:h-80 bg-gray-100 rounded-lg overflow-hidden shadow-sm border border-gray-200">
                  <img 
                    src={imagePreview} 
                    alt="Uploaded" 
                    className="w-full h-full object-contain" 
                    loading="lazy"
                  />
                </div>
                <button
                  onClick={() => setImagePreview(null)}
                  className="absolute top-2 right-2 p-1.5 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors"
                  aria-label="Remove image"
                >
                  <X size={18} className="text-gray-600" />
                </button>
              </div>
            ) : (
              <div className="w-full h-64 md:h-80 border-2 border-dashed border-blue-300 rounded-lg flex flex-col items-center justify-center text-blue-500 bg-blue-50 bg-opacity-50">
                <ImageIcon size={48} className="mb-2" />
                <p className="text-center text-sm md:text-base text-gray-600">
                  Upload an image or take a photo
                </p>
              </div>
            )}

            <div className="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-3 mt-4">
              <label className="flex-1 flex items-center justify-center px-4 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 cursor-pointer shadow-sm touch-target">
                <input 
                  ref={fileInputRef} 
                  type="file" 
                  accept="image/*" 
                  onChange={handleImageUpload} 
                  className="hidden" 
                />
                <Upload size={20} className="mr-2" />
                <span className="text-sm md:text-base">Upload Image</span>
              </label>
              <button
                onClick={handleCameraCapture}
                className="flex-1 flex items-center justify-center px-4 py-2.5 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200 shadow-sm touch-target"
              >
                <Camera size={20} className="mr-2" />
                <span className="text-sm md:text-base">Take Photo</span>
              </button>
            </div>
          </div>

          {/* Translation result area */}
          <div className="w-full md:w-1/2 bg-gray-50 p-4 md:p-6 rounded-lg shadow-sm border border-gray-200">
            <h2 className="text-xl font-semibold mb-4 text-blue-600">Translation Result</h2>
            {isTranslating ? (
              <div className="flex items-center justify-center h-40">
                <div className="flex flex-col items-center">
                  <Loader className="animate-spin text-blue-600 mb-2" size={32} />
                  <span className="text-gray-600 text-sm md:text-base">Translating...</span>
                </div>
              </div>
            ) : translatedText ? (
              <div className="bg-white p-4 rounded-lg border border-gray-200 min-h-[200px] max-h-[300px] overflow-y-auto">
                <p className="text-gray-800 whitespace-pre-line">{translatedText}</p>
              </div>
            ) : (
              <div className="flex items-center justify-center h-40 text-gray-500">
                <p className="text-center text-sm md:text-base">
                  Translated text will appear here after processing the image
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Hidden video and canvas elements for camera capture */}
        <video ref={videoRef} className="hidden" width="640" height="480" />
        <canvas ref={canvasRef} className="hidden" width="640" height="480" />
      </div>
    </div>
  );
}

export default ImageTranslation;
