import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs, deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Loader2, Trash2, ExternalLink } from 'lucide-react';
import { auth } from '../firebaseConfig';
import SEO from '../components/SEO';

// Initialize Firestore
const db = getFirestore();

function SavedTranslations() {
  const [phrases, setPhrases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userId = auth.currentUser?.uid;

  useEffect(() => {
    if (!userId) {
      navigate('/');
      return;
    }

    const fetchPhrases = async () => {
      try {
        const phrasesRef = collection(db, 'savedConversations');
        const q = query(
          phrasesRef,
          where('userId', '==', userId),
          orderBy('timestamp', 'desc')
        );
        
        const snapshot = await getDocs(q);
        const fetchedPhrases = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPhrases(fetchedPhrases);
      } catch (err) {
        console.error('Error fetching phrases:', err);
        setError('Failed to fetch saved translations. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchPhrases();
  }, [userId, navigate]);

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this translation?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'savedConversations', id));
      setPhrases(phrases.filter(phrase => phrase.id !== id));
    } catch (err) {
      console.error('Error deleting phrase:', err);
      setError('Failed to delete translation. Please try again.');
    }
  };

  // Create schema for structured data
  const savedTranslationsSchema = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "RealTranslate Saved Translations",
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Web",
    "description": "Access your saved translations from RealTranslate. View, manage, and reuse your previously translated content."
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  if (phrases.length === 0) {
    return (
      <div className="container mx-auto p-4">
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-center text-gray-500">
            No saved translations yet. Translations you save will appear here.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Saved Translations | RealTranslate"
        description="Access your saved translations from RealTranslate. View, manage, and reuse your previously translated content."
        canonicalUrl="/saved"
        schema={savedTranslationsSchema}
      />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Saved Translations</h1>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {phrases.map((phrase) => (
            <div key={phrase.id} className="bg-white shadow rounded-lg overflow-hidden">
              <div className="px-4 py-3 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium">
                    {phrase.sourceLang} → {phrase.targetLang}
                  </span>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => navigate(`/text/${phrase.sourceLang}/${phrase.targetLang}`, {
                        state: { text: phrase.sourceText }
                      })}
                      className="text-gray-500 hover:text-gray-700"
                      title="Open in translator"
                    >
                      <ExternalLink size={16} />
                    </button>
                    <button
                      onClick={() => handleDelete(phrase.id)}
                      className="text-red-500 hover:text-red-700"
                      title="Delete translation"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <div className="space-y-2">
                  <div className="text-sm">
                    <span className="font-medium">Original:</span>
                    <p className="mt-1">{phrase.inputText}</p>
                  </div>
                  <div className="text-sm">
                    <span className="font-medium">Translation:</span>
                    <p className="mt-1">{phrase.translatedText}</p>
                  </div>
                  <div className="text-xs text-gray-500">
                    Saved on {new Date(phrase.timestamp?.toDate()).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SavedTranslations;