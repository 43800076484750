import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { X } from 'lucide-react';

const firebaseConfig = {
  apiKey: "AIzaSyDS6n3LinW8cMiJYzVNbosutDZwkqYs38s",
  authDomain: "realtranslate-15050.firebaseapp.com",
  projectId: "realtranslate-15050",
  storageBucket: "realtranslate-15050.appspot.com",
  messagingSenderId: "409027291793",
  appId: "1:409027291793:web:8cd5fb2ccc88a151076d30",
  measurementId: "G-DF36CKE447"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

function AuthComponent({ onClose }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async (providerName) => {
    setIsLoading(true);
    setAuthError(null);
    
    try {
      await signInWithPopup(auth, provider);
      if (onClose) onClose();
    } catch (error) {
      console.error(`Error signing in with ${providerName}`, error);
      setAuthError(`Failed to sign in with ${providerName}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    setIsLoading(true);
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out", error);
    } finally {
      setIsLoading(false);
    }
  };

  // If user is already signed in, show profile info
  if (user) {
    return (
      <div className="bg-white rounded-3xl shadow-2xl overflow-hidden max-w-md w-full mx-auto animate-fade-in">
        <div className="relative">
          <div className="absolute top-4 right-4 z-10">
            {onClose && (
              <button 
                onClick={onClose}
                className="p-2 rounded-full bg-white/80 hover:bg-white transition-colors shadow-md"
              >
                <X size={20} className="text-gray-600" />
              </button>
            )}
          </div>
          
          <div className="h-32 bg-gradient-to-r from-blue-500 to-indigo-600"></div>
          
          <div className="flex justify-center">
            <div className="relative -mt-16">
              <img
                src={user.photoURL}
                alt={user.displayName}
                className="w-32 h-32 rounded-full border-4 border-white shadow-lg object-cover"
              />
              <div className="absolute bottom-0 right-0 bg-green-500 w-6 h-6 rounded-full border-4 border-white"></div>
            </div>
          </div>
        </div>
        
        <div className="px-8 py-6 text-center">
          <h2 className="text-2xl font-bold text-gray-800">{user.displayName}</h2>
          <p className="text-gray-500 mb-6">{user.email}</p>
          
          <div className="flex flex-col gap-4">
            <button
              onClick={handleSignOut}
              disabled={isLoading}
              className="w-full py-3 px-4 bg-red-500 text-white rounded-xl font-medium
                transition-all duration-300 hover:bg-red-600 hover:shadow-lg
                disabled:bg-gray-300 disabled:cursor-not-allowed flex items-center justify-center"
            >
              {isLoading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Signing out...
                </span>
              ) : (
                'Sign Out'
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Sign in screen
  return (
    <div className="bg-white rounded-3xl shadow-2xl overflow-hidden max-w-md w-full mx-auto animate-fade-in">
      {onClose && (
        <div className="absolute top-4 right-4">
          <button 
            onClick={onClose}
            className="p-2 rounded-full bg-white/80 hover:bg-white transition-colors shadow-md"
          >
            <X size={20} className="text-gray-600" />
          </button>
        </div>
      )}
      
      <div className="p-8">
        <div className="text-center mb-8">
          <div className="inline-block p-4 bg-blue-50 rounded-full mb-4">
            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5.98145 18.6913C6.54639 17.5806 7.40768 16.6478 8.46997 15.9963C9.53226 15.3448 10.7541 15 12.0003 15C13.2464 15 14.4683 15.3448 15.5306 15.9963C16.5929 16.6478 17.4542 17.5806 18.0191 18.6913" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Welcome to RealTranslate</h2>
          <p className="text-gray-500">Sign in to save your translations and access premium features</p>
        </div>
        
        {authError && (
          <div className="mb-6 p-4 bg-red-50 rounded-xl text-red-600 text-sm">
            {authError}
          </div>
        )}
        
        <div className="space-y-4">
          <button
            onClick={() => handleSignIn('Google')}
            disabled={isLoading}
            className="w-full py-3 px-4 bg-white border border-gray-300 rounded-xl font-medium
              transition-all duration-300 hover:bg-gray-50 hover:shadow-md
              disabled:bg-gray-100 disabled:cursor-not-allowed flex items-center justify-center"
          >
            {isLoading ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Signing in...
              </span>
            ) : (
              <>
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                  <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                  <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                  <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                </svg>
                Continue with Google
              </>
            )}
          </button>
          
          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with</span>
            </div>
          </div>
          
          <button
            onClick={() => {/* Add Apple sign in logic */}}
            className="w-full py-3 px-4 bg-black text-white rounded-xl font-medium
              transition-all duration-300 hover:bg-gray-900 hover:shadow-md flex items-center justify-center"
          >
            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.05 20.28c-.98.95-2.05.02-3.18.02-1.14 0-2.22.91-3.18-.02-2.26.24-3.11-1.01-3.11-1.01-1.68-2.44-1.36-5.68-1.36-5.68C3.23 13.21 3 11.45 3 11.45c-.5-4.15 2.92-6.2 2.92-6.2 1.03-.7 2.72-1 2.72-1 1.14-.13 2.36.01 2.36.01l.01-.5c0-.5 1.09-.5 1.09-.5l.01.5c.7-.09 2.36-.01 2.36-.01s1.69.3 2.72 1c0 0 3.42 2.05 2.92 6.2 0 0-.23 1.76-3.22 2.14 0 0 .32 3.24-1.36 5.68 0 0-.85 1.25-3.11 1.01z"/>
            </svg>
            Continue with Apple
          </button>
        </div>
        
        <div className="mt-8 text-center text-sm text-gray-500">
          By continuing, you agree to our 
          <a href="/terms" className="text-blue-600 hover:underline ml-1">Terms of Service</a> and 
          <a href="/privacy" className="text-blue-600 hover:underline ml-1">Privacy Policy</a>.
        </div>
      </div>
      
      <div className="px-8 py-6 bg-gray-50 border-t border-gray-100 text-center">
        <p className="text-sm text-gray-600">
          Don't have an account? Sign up with one of the options above.
        </p>
      </div>
    </div>
  );
}

export default AuthComponent;