import React, { useState, useCallback, useEffect, useMemo, useContext, useRef } from 'react';
import { ArrowRightLeft, Volume2, Clipboard, BookmarkIcon, X, ChevronDown } from 'lucide-react';
import LanguageSelector from '../components/LanguageSelector';
import { LANGUAGES } from '../data/languages';
import debounce from 'lodash/debounce';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import AuthComponent from '../components/AuthComponent';
import Modal from 'react-modal';
import { SubscriptionContext } from '../context/SubscriptionContext';
import { auth, analytics } from '../firebaseConfig';
import { logEvent } from "firebase/analytics";


Modal.setAppElement('#root');

function TextTranslation() {
  const { source, target } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const redirectState = location.state || {};
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const isFreshTranslationRef = useRef(false);
  const prevInputText = useRef('');
  const db = getFirestore();
  const { isSubscribed } = useContext(SubscriptionContext);
  const auth = getAuth();

  // State for tracking if user has seen initial checkout
  const [hasSeenInitialCheckout, setHasSeenInitialCheckout] = useState(() => {
    return localStorage.getItem('hasSeenInitialCheckout') === 'true';
  });

  // State for translation history
  const [translationsHistory, setTranslationsHistory] = useState(() => {
    const stored = localStorage.getItem('translationsHistory');
    return stored ? JSON.parse(stored) : [];
  });

  // Add state for mobile detection
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  // Add resize listener for responsive behavior
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const findLanguage = (identifier) => {
    if (!identifier) return null;
    const normalizedIdentifier = identifier.toLowerCase();
    return LANGUAGES.find(
      lang => lang.id.toLowerCase() === normalizedIdentifier || 
              lang.name.toLowerCase() === normalizedIdentifier
    );
  };

  const [sourceLanguage, setSourceLanguage] = useState(() => {
    return findLanguage(source) || 
           findLanguage(redirectState.source) || 
           LANGUAGES.find(lang => lang.id === 'en');
  });

  const [targetLanguage, setTargetLanguage] = useState(() => {
    return findLanguage(target) || 
           findLanguage(redirectState.target) || 
           LANGUAGES.find(lang => lang.id === 'es');
  });

  // Create SEO schema for structured data
  const translationSchema = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "RealTranslate Text Translation",
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": `Translate text from ${sourceLanguage?.name || 'any language'} to ${targetLanguage?.name || 'any language'} for free with RealTranslate.`
  };

  const [showSourceSelector, setShowSourceSelector] = useState(false);
  const [showTargetSelector, setShowTargetSelector] = useState(false);
  const [inputText, setInputText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const [user, setUser] = useState(null);

  const handleMaybeLater = () => {
    logEvent(analytics, 'limit_modal_action', {
      action: 'dismiss',
      user_id: user?.uid || 'anonymous',
      translations_count: getCurrentTranslationCount(),
      next_available: getTimeUntilNext() ? `${getTimeUntilNext().hours}h ${getTimeUntilNext().minutes}m` : 'N/A',
      context: 'translation'
    });
    
    setShowSubscribeModal(false);
  };
  
  const handleSubscribeClick = () => {
    logEvent(analytics, 'limit_modal_action', {
      action: 'subscribe',
      user_id: user?.uid || 'anonymous',
      translations_count: getCurrentTranslationCount(),
      next_available: getTimeUntilNext() ? `${getTimeUntilNext().hours}h ${getTimeUntilNext().minutes}m` : 'N/A',
      context: 'translation'
    });
  
    navigate('/checkout', { 
      state: { 
        from: 'translation',
        returnUrl: window.location.pathname,
        source: 'limit_modal'
      } 
    });
  };

  // Store translations history
  useEffect(() => {
    localStorage.setItem('translationsHistory', JSON.stringify(translationsHistory));
  }, [translationsHistory]);

  // Store hasSeenInitialCheckout state
  useEffect(() => {
    localStorage.setItem('hasSeenInitialCheckout', hasSeenInitialCheckout.toString());
  }, [hasSeenInitialCheckout]);

  // Clean up old translations
  useEffect(() => {
    const twentyFourHoursAgo = Date.now() - (24 * 60 * 60 * 1000);
    const filtered = translationsHistory.filter(timestamp => timestamp > twentyFourHoursAgo);
    if (filtered.length !== translationsHistory.length) {
      setTranslationsHistory(filtered);
    }
  }, [translationsHistory]);

  // Get current translation count
  const getCurrentTranslationCount = useCallback(() => {
    const twentyFourHoursAgo = Date.now() - (24 * 60 * 60 * 1000);
    return translationsHistory.filter(timestamp => timestamp > twentyFourHoursAgo).length;
  }, [translationsHistory]);

  

  // Get time until next translation
  const getTimeUntilNext = useCallback(() => {
    if (translationsHistory.length === 0) return null;
    const twentyFourHoursAgo = Date.now() - (24 * 60 * 60 * 1000);
    const oldestInWindow = translationsHistory
      .filter(timestamp => timestamp > twentyFourHoursAgo)
      .sort((a, b) => a - b)[0];
    
    if (!oldestInWindow) return null;
    
    const timeUntilExpiry = oldestInWindow + (24 * 60 * 60 * 1000) - Date.now();
    if (timeUntilExpiry <= 0) return null;

    const hours = Math.floor(timeUntilExpiry / (60 * 60 * 1000));
    const minutes = Math.floor((timeUntilExpiry % (60 * 60 * 1000)) / (60 * 1000));
    return { hours, minutes };
  }, [translationsHistory]);

  // Handle initial checkout redirect
  useEffect(() => {
    const currentCount = getCurrentTranslationCount();
    if (!isSubscribed && currentCount >= 5 && !hasSeenInitialCheckout) {
      setHasSeenInitialCheckout(true);
      navigate('/checkout', { 
        state: { 
          from: 'translation',
          returnUrl: window.location.pathname,
          isDismissible: true
        } 
      });
    }
  }, [getCurrentTranslationCount, isSubscribed, hasSeenInitialCheckout, navigate]);

  // Handle auth state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user && showAuthModal) {
        setShowAuthModal(false);
      }
    });
    return () => unsubscribe();
  }, [auth, showAuthModal]);

  // Handle URL params for languages
  useEffect(() => {
    const newSourceLang = findLanguage(source) || findLanguage(redirectState.source);
    const newTargetLang = findLanguage(target) || findLanguage(redirectState.target);

    if (newSourceLang) setSourceLanguage(newSourceLang);
    if (newTargetLang) setTargetLanguage(newTargetLang);
  }, [source, target, redirectState.source, redirectState.target]);

  

  const showNotification = (message, success = true) => {
    setToastMessage(message);
    setIsSuccess(success);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      if (text) setInputText(text);
    } catch (error) {
      console.error('Failed to paste:', error);
      showNotification('Failed to paste from clipboard. Please try manually.', false);
    }
  };

  const saveTranslation = async () => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }

    if (!inputText || !translatedText) {
      showNotification('Please enter text to translate first', false);
      return;
    }

    try {
      await addDoc(collection(db, 'savedConversations'), {
        userId: user.uid,
        fromLanguage: sourceLanguage.id,
        toLanguage: targetLanguage.id,
        inputText,
        translatedText,
        timestamp: serverTimestamp(),
      });
      showNotification('Translation saved successfully!', true);
    } catch (error) {
      console.error('Error saving translation:', error);
      showNotification('Failed to save translation. Please try again.', false);
    }
  };

  const performTranslation = useCallback(async (text, sourceLang, targetLang) => {
    if (!text.trim()) {
      setTranslatedText('');
      return;
    }
  
    if (isFreshTranslationRef.current && !isSubscribed) {
      setTranslationsHistory(prev => [...prev, Date.now()]);
      isFreshTranslationRef.current = false;
    }
  
    setIsTranslating(true);
  
    try {
      const response = await fetch(
        'https://me25gad3gc.execute-api.us-east-2.amazonaws.com/prod/translate',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            text,
            languageFrom: sourceLang,
            languageTo: targetLang,
            userId: user ? user.uid : 'anonymous',
            startNewConversation: true,
          }),
        }
      );
  
      if (!response.ok) throw new Error('Translation API response was not ok');
  
      const data = await response.json();
      setTranslatedText(data.translatedText);
    } catch (error) {
      console.error('Translation error:', error);
      setTranslatedText('An error occurred during translation.');
    } finally {
      setIsTranslating(false);
    }
  }, [user, isSubscribed]);

  useEffect(() => {
    return () => {
      setShowSubscribeModal(false);
    };
  }, []);

  // Optimize translation function with debounce
  const debouncedTranslate = useCallback(
    debounce(async (text) => {
      if (!text.trim()) {
        setTranslatedText('');
        return;
      }

      try {
        const currentCount = getCurrentTranslationCount();
        if (!isSubscribed && currentCount >= 5) {
          setShowSubscribeModal(true);
          return;
        }
        performTranslation(text, sourceLanguage.id, targetLanguage.id);
      } catch (error) {
        console.error('Translation error:', error);
      }
    }, 500),
    [sourceLanguage, targetLanguage]
  );

  // Reset translation flag when input changes
  useEffect(() => {
    if (!prevInputText.current.trim() && inputText.trim()) {
      isFreshTranslationRef.current = true;
    } else if (!inputText.trim()) {
      isFreshTranslationRef.current = false;
    }
    prevInputText.current = inputText;
  }, [inputText]);

  // Handle input text changes
  useEffect(() => {
    if (inputText.trim()) {
      const currentCount = getCurrentTranslationCount();
      if (!isSubscribed && currentCount >= 5) {
        setShowSubscribeModal(true);
        return;
      }
      debouncedTranslate(inputText);
    } else {
      setTranslatedText('');
    }
  
    return () => debouncedTranslate.cancel();
  }, [inputText, debouncedTranslate, isSubscribed, getCurrentTranslationCount]);

  const handleSwapLanguages = () => {
    const tempLang = sourceLanguage;
    setSourceLanguage(targetLanguage);
    setTargetLanguage(tempLang);
  };

  const speakText = useCallback((text, language) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = language.bcp47 || language.id;
      speechSynthesis.speak(utterance);
    }
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <SEO 
        title={`Translate ${sourceLanguage?.name || 'Text'} to ${targetLanguage?.name || 'Any Language'} | RealTranslate`}
        description={`Free online translation from ${sourceLanguage?.name || 'any language'} to ${targetLanguage?.name || 'any language'}. Translate text instantly with RealTranslate.`}
        canonicalUrl={`/text/${sourceLanguage?.id || 'en'}/${targetLanguage?.id || 'fr'}`}
        schema={translationSchema}
      />
      
      {/* Translation limit banner */}
      {!isSubscribed && getCurrentTranslationCount() >= 5 && (
        <div className="bg-blue-50 p-3 text-center">
          <p className="text-blue-800 text-sm md:text-base">
            You've reached your daily limit of 5 free translations.{' '}
            <button
              onClick={() => setShowSubscribeModal(true)}
              className="font-medium underline hover:text-blue-600 focus:outline-none"
            >
              Upgrade now
            </button>{' '}
            for unlimited translations.
          </p>
        </div>
      )}

      {/* Language selection bar */}
      <div className="bg-white border-b border-gray-200 sticky top-16 z-20 shadow-sm">
        <div className="max-w-4xl mx-auto px-4 py-3 flex items-center justify-between">
          <div className="flex items-center space-x-2 md:space-x-4">
            {/* Source language selector */}
            <div className="relative">
              <button
                onClick={() => setShowSourceSelector(!showSourceSelector)}
                className="flex items-center space-x-1 px-3 py-1.5 border rounded-lg hover:bg-gray-50 transition-colors text-sm md:text-base touch-target"
              >
                <span className="max-w-[100px] truncate">{sourceLanguage.name}</span>
                <ChevronDown size={16} />
              </button>
              {showSourceSelector && (
                <div className="absolute z-30 mt-1 w-screen max-w-[280px] sm:max-w-[320px]">
                  <LanguageSelector
                    title="Translate from"
                    selectedLanguage={sourceLanguage}
                    onSelectLanguage={(lang) => {
                      setSourceLanguage(lang);
                      setShowSourceSelector(false);
                    }}
                    showDetect={true}
                  />
                </div>
              )}
            </div>

            {/* Swap languages button */}
            <button
              onClick={handleSwapLanguages}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors touch-target"
              aria-label="Swap languages"
            >
              <ArrowRightLeft size={20} className="text-blue-600" />
            </button>

            {/* Target language selector */}
            <div className="relative">
              <button
                onClick={() => setShowTargetSelector(!showTargetSelector)}
                className="flex items-center space-x-1 px-3 py-1.5 border rounded-lg hover:bg-gray-50 transition-colors text-sm md:text-base touch-target"
              >
                <span className="max-w-[100px] truncate">{targetLanguage.name}</span>
                <ChevronDown size={16} />
              </button>
              {showTargetSelector && (
                <div className="absolute z-30 mt-1 w-screen max-w-[280px] sm:max-w-[320px]">
                  <LanguageSelector
                    title="Translate to"
                    selectedLanguage={targetLanguage}
                    onSelectLanguage={(lang) => {
                      setTargetLanguage(lang);
                      setShowTargetSelector(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Translation count indicator */}
          {!isSubscribed && (
            <div className="hidden sm:block text-sm text-gray-500">
              {getCurrentTranslationCount()}/5 translations
            </div>
          )}
        </div>
      </div>

      {/* Main content area */}
      <div className="flex-1 flex flex-col md:flex-row max-w-4xl mx-auto w-full p-4 gap-4">
        {/* Source text area */}
        <div className="flex-1 flex flex-col">
          <div className="flex-1 flex flex-col bg-white rounded-lg shadow-sm border border-gray-200 relative">
            <textarea
              className="w-full flex-1 text-base md:text-lg p-4 border-none resize-none focus:ring-0 focus:outline-none min-h-[200px] md:min-h-[400px]"
              placeholder="Enter text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              aria-label="Source text"
            />
            <div className="absolute bottom-2 left-2 right-2 flex items-center justify-between px-2">
              <button 
                onClick={() => speakText(inputText, sourceLanguage)}
                className={`p-2 ${inputText ? 'text-blue-600' : 'text-gray-300'} hover:bg-blue-50 rounded-full transition-colors touch-target`}
                aria-label="Speak source text"
                disabled={!inputText}
              >
                <Volume2 className="w-5 h-5 md:w-6 md:h-6" />
              </button>
              <div className="flex space-x-1">
                <button
                  onClick={handlePaste}
                  className="p-2 text-gray-500 hover:bg-gray-100 rounded-full transition-colors touch-target"
                  aria-label="Paste text"
                >
                  <Clipboard className="w-5 h-5 md:w-6 md:h-6" />
                </button>
                {inputText && (
                  <button 
                    onClick={() => setInputText('')}
                    className="p-2 text-gray-500 hover:bg-gray-100 rounded-full transition-colors touch-target"
                    aria-label="Clear text"
                  >
                    <X className="w-5 h-5 md:w-6 md:h-6" />
                  </button>
                )}
              </div>
            </div>
          </div>
          {!inputText && (
            <button
              onClick={handlePaste}
              className="mt-2 text-blue-600 text-sm hover:underline"
            >
              Paste text
            </button>
          )}
        </div>

        {/* Target text area */}
        <div className="flex-1 flex flex-col">
          <div className="flex-1 flex flex-col bg-gray-50 rounded-lg shadow-sm border border-gray-200 relative">
            <textarea
              className="w-full flex-1 text-base md:text-lg p-4 border-none resize-none focus:ring-0 focus:outline-none bg-gray-50 min-h-[200px] md:min-h-[400px]"
              placeholder="Translation"
              value={translatedText}
              readOnly
              aria-label="Translated text"
            />
            <div className="absolute bottom-2 left-2 right-2 flex items-center justify-between px-2">
              <button 
                onClick={() => speakText(translatedText, targetLanguage)}
                className={`p-2 ${translatedText ? 'text-blue-600' : 'text-gray-300'} hover:bg-blue-50 rounded-full transition-colors touch-target`}
                aria-label="Speak translated text"
                disabled={!translatedText}
              >
                <Volume2 className="w-5 h-5 md:w-6 md:h-6" />
              </button>
              {translatedText && (
                <div className="flex space-x-1">
                  <button 
                    onClick={() => {
                      navigator.clipboard.writeText(translatedText);
                      showNotification('Copied to clipboard');
                    }}
                    className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors touch-target"
                    aria-label="Copy translation"
                  >
                    <Clipboard className="w-5 h-5 md:w-6 md:h-6" />
                  </button>
                  <button 
                    onClick={saveTranslation}
                    className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors touch-target"
                    title="Save translation"
                    aria-label="Save translation"
                  >
                    <BookmarkIcon className="w-5 h-5 md:w-6 md:h-6" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Toast notification */}
      {showToast && (
        <div 
          className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 px-4 py-3 rounded-lg shadow-lg ${
            isSuccess ? 'bg-green-500' : 'bg-red-500'
          } text-white text-sm md:text-base max-w-xs md:max-w-md animate-fade-in`}
        >
          {toastMessage}
        </div>
      )}

      {/* Auth Modal */}
      <Modal
        isOpen={showAuthModal}
        onRequestClose={() => setShowAuthModal(false)}
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
      >
        <AuthComponent onClose={() => setShowAuthModal(false)} />
      </Modal>

      {/* Subscribe Modal */}
      <Modal
        isOpen={showSubscribeModal}
        onRequestClose={handleMaybeLater}
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
      >
        <div className="bg-white rounded-3xl shadow-2xl overflow-hidden max-w-md w-full mx-auto animate-fade-in">
          <div className="relative">
            <div className="h-32 bg-gradient-to-r from-blue-500 to-indigo-600 flex items-center justify-center">
              <div className="absolute top-4 right-4 z-10">
                <button 
                  onClick={handleMaybeLater}
                  className="p-2 rounded-full bg-white/80 hover:bg-white transition-colors shadow-md"
                >
                  <X size={20} className="text-gray-600" />
                </button>
              </div>
              <div className="bg-white rounded-full p-4 shadow-lg">
                <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M16 8L8 16" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M8 8L16 16" stroke="#0066FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          
          <div className="px-8 py-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-2">Translation Limit Reached</h2>
              <p className="text-gray-600">
                You've reached your limit of 5 free translations in 24 hours.
                {getTimeUntilNext() && (
                  <span className="block mt-2 text-sm font-medium text-blue-600">
                    Next translation will be available in {getTimeUntilNext().hours}h {getTimeUntilNext().minutes}m
                  </span>
                )}
              </p>
            </div>
            
            <div className="bg-blue-50 rounded-xl p-4 mb-6">
              <p className="text-blue-800">
                Subscribe now to get unlimited translations and additional features.
              </p>
            </div>
            
            <div className="space-y-4">
              <button
                onClick={handleSubscribeClick}
                className="w-full py-3 px-4 bg-blue-600 text-white rounded-xl font-medium
                  transition-all duration-300 hover:bg-blue-700 hover:shadow-lg flex items-center justify-center"
              >
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.25 6.375C20.25 8.65317 16.5563 10.5 12 10.5C7.44365 10.5 3.75 8.65317 3.75 6.375M20.25 6.375C20.25 4.09683 16.5563 2.25 12 2.25C7.44365 2.25 3.75 4.09683 3.75 6.375M20.25 6.375V17.625C20.25 19.9032 16.5563 21.75 12 21.75C7.44365 21.75 3.75 19.9032 3.75 17.625V6.375M20.25 12C20.25 14.2782 16.5563 16.125 12 16.125C7.44365 16.125 3.75 14.2782 3.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Subscribe Now
              </button>
              
              <button
                onClick={handleMaybeLater}
                className="w-full py-3 px-4 bg-white border border-gray-300 text-gray-700 rounded-xl font-medium
                  transition-all duration-300 hover:bg-gray-50 hover:shadow-md flex items-center justify-center"
              >
                Maybe Later
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TextTranslation;