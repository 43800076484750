import React from 'react';
import { Link } from 'react-router-dom';
import { Type, Image, MessageSquare } from 'lucide-react';
import TextTranslation from './TextTranslation';
import SEO from '../components/SEO';

function Home() {
  // Create schema for structured data
  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "RealTranslate",
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "RealTranslate allows everyone to translate over 100 languages for free, including text and images. Start translating with ease.",
    "featureList": [
      "Text translation",
      "Image translation",
      "Conversation translation",
      "100+ languages supported",
      "Free to use"
    ]
  };

  return (
    <div>
      <SEO 
        title="RealTranslate - Free Translation for Text and Images"
        description="Translate over 100 languages for free. RealTranslate makes it easy to translate text and images instantly."
        canonicalUrl="/"
        schema={homeSchema}
      />
      
      {/* Text Translation Section */}
      <TextTranslation />

      {/* Rest of Homepage Content */}
      <div className="max-w-4xl mx-auto p-6">
        <div className="mt-12">
          {/* <h2 className="text-2xl font-bold mb-4 text-blue-600">Additional Translation Methods:</h2> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* <TranslationOption
              to="/image"
              icon={<Image size={48} className="text-blue-500" />}
              title="Image Translation"
              description="Translate text from images"
            /> */}
            {/* <TranslationOption
              to="/conversation"
              icon={<MessageSquare size={48} className="text-blue-500" />}
              title="Conversation Translation"
              description="Real-time conversation translation"
            /> */}
          </div>
        </div>

        <div className="mt-12">
          <h2 className="text-2xl font-bold mb-4 text-blue-600">Popular Translations:</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <LanguagePair source="english" target="spanish" />
            <LanguagePair source="english" target="french" />
            <LanguagePair source="english" target="japanese" />
            <LanguagePair source="english" target="german" />
            <LanguagePair source="english" target="chinese" />
            <LanguagePair source="english" target="russian" />
            <LanguagePair source="spanish" target="english" />
            <LanguagePair source="french" target="english" />
            <LanguagePair source="english" target="swahili"/>
            <LanguagePair source="japanese" target="english" />
            <LanguagePair source="german" target="english" />
          </div>
        </div>
      </div>
    </div>
  );
}

function TranslationOption({ to, icon, title, description }) {
  return (
    <Link to={to} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 border border-blue-100 hover:border-blue-300">
      <div className="flex flex-col items-center">
        {icon}
        <h2 className="text-xl font-semibold mb-2 text-blue-600 mt-4">{title}</h2>
        <p className="text-center text-gray-600">{description}</p>
      </div>
    </Link>
  );
}

function LanguagePair({ source, target }) {
  const sourceCap = source.charAt(0).toUpperCase() + source.slice(1);
  const targetCap = target.charAt(0).toUpperCase() + target.slice(1);
  
  return (
    <Link 
      to={`/text/${source}/${target}`} 
      className="text-blue-600 hover:underline flex items-center p-2 rounded-lg hover:bg-blue-50 transition-all duration-200"
    >
      <span>{sourceCap} to {targetCap}</span>
      <Type size={16} className="ml-2 inline-block" />
    </Link>
  );
}

export default Home;