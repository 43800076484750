import { Mic, ArrowLeftRight, X } from 'lucide-react';
import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { SubscriptionContext } from '../context/SubscriptionContext';
import SEO from '../components/SEO';

function ConversationTranslation() {
  const navigate = useNavigate();
  const { isSubscribed } = useContext(SubscriptionContext);
  const [recognition, setRecognition] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [activeMic, setActiveMic] = useState(null);
  const [fromLanguage, setFromLanguage] = useState({ id: 'en', name: 'English' });
  const [toLanguage, setToLanguage] = useState({ id: 'fr', name: 'French' });
  const [topText, setTopText] = useState('');
  const [bottomText, setBottomText] = useState('');
  const [topTranslation, setTopTranslation] = useState('');
  const [bottomTranslation, setBottomTranslation] = useState('');
  const [showFromLanguageSelector, setShowFromLanguageSelector] = useState(false);
  const [showToLanguageSelector, setShowToLanguageSelector] = useState(false);
  const [interimText, setInterimText] = useState('');
  const [translationCount, setTranslationCount] = useState(
    parseInt(localStorage.getItem('translationCount')) || 0
  );
  const [hasRedirected, setHasRedirected] = useState(
    localStorage.getItem('hasRedirected') === 'true'
  );

  const topScrollRef = useRef(null);
  const bottomScrollRef = useRef(null);
  const translationTimer = useRef(null);

  // Add state for mobile detection
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  // Add resize listener for responsive behavior
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    localStorage.setItem('translationCount', translationCount);
    localStorage.setItem('hasRedirected', hasRedirected);
  }, [translationCount, hasRedirected]);

  const shouldRedirectToCheckout = () => {
    if (!isSubscribed && translationCount >= 1 && !hasRedirected) {
      setHasRedirected(true);
      return true;
    }
    return false;
  };

  const cleanupRecognition = () => {
    if (recognition) {
      recognition.stop();
    }
    setIsListening(false);
    setActiveMic(null);
    setInterimText('');
  };

  const clearTexts = (position) => {
    if (position === 'top') {
      setTopText('');
      setBottomTranslation('');
    } else {
      setBottomText('');
      setTopTranslation('');
    }
    setInterimText('');
  };

  useEffect(() => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;

      recognitionInstance.onstart = () => {
        setIsListening(true);
      };

      recognitionInstance.onend = () => {
        cleanupRecognition();
      };

      recognitionInstance.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        cleanupRecognition();
      };

      recognitionInstance.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
            if (activeMic === 'top') {
              setTopText(transcript);
              translateText(transcript, 'top');
            } else {
              setBottomText(transcript);
              translateText(transcript, 'bottom');
            }
          } else {
            interimTranscript += transcript;
          }
        }

        setInterimText(interimTranscript);

        if (interimTranscript) {
          if (translationTimer.current) {
            clearTimeout(translationTimer.current);
          }
          translationTimer.current = setTimeout(() => {
            translateText(interimTranscript, activeMic);
          }, 500);
        }
      };

      setRecognition(recognitionInstance);

      return () => {
        cleanupRecognition();
      };
    }
  }, [activeMic]);

  const translateText = async (text, position) => {
    if (!text.trim()) return;

    try {
      const sourceLang = position === 'top' ? fromLanguage.id : toLanguage.id;
      const targetLang = position === 'top' ? toLanguage.id : fromLanguage.id;
      
      const response = await fetch('https://me25gad3gc.execute-api.us-east-2.amazonaws.com/prod/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: text,
          languageFrom: sourceLang,
          languageTo: targetLang,
          userId: 'user-id-placeholder',
          startNewConversation: false,
        }),
      });

      const data = await response.json();
      
      setTranslationCount(prevCount => {
        const newCount = prevCount + 1;
        if (shouldRedirectToCheckout()) {
          navigate('/checkout');
        }
        return newCount;
      });

      if (position === 'top') {
        setBottomTranslation(data.translatedText);
      } else {
        setTopTranslation(data.translatedText);
      }
    } catch (error) {
      console.error('Translation failed:', error);
    }
  };

  const handleMicClick = async (position) => {
    if (shouldRedirectToCheckout()) {
      navigate('/checkout');
      return;
    }

    if (!recognition) return;

    if (position === activeMic && isListening) {
      cleanupRecognition();
    } else {
      if (isListening) {
        cleanupRecognition();
      }
      
      clearTexts(position);
      
      recognition.lang = position === 'top' ? fromLanguage.id : toLanguage.id;
      setActiveMic(position);
      recognition.start();
    }
  };

  const handleFromLanguageSelect = (language) => {
    setFromLanguage(language);
    setShowFromLanguageSelector(false);
  };

  const handleToLanguageSelect = (language) => {
    setToLanguage(language);
    setShowToLanguageSelector(false);
  };

  const getDisplayText = (position) => {
    if (position === 'top') {
      return activeMic === 'top' && isListening ? (topText + interimText) : topText;
    } else {
      return activeMic === 'bottom' && isListening ? (bottomText + interimText) : bottomText;
    }
  };

  // Create schema for structured data
  const conversationSchema = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "RealTranslate Conversation Translation",
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "Real-time conversation translation between multiple languages. Translate conversations instantly with RealTranslate."
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Conversation Translation | RealTranslate"
        description="Real-time conversation translation between multiple languages. Translate conversations instantly with RealTranslate."
        canonicalUrl="/conversation"
        schema={conversationSchema}
      />
      
      <div className="min-h-screen bg-white flex flex-col">
        {/* Translation limit banner */}
        {!isSubscribed && translationCount >= 1 && (
          <div className="bg-blue-50 p-3 text-center">
            <p className="text-blue-800 text-sm md:text-base">
              You've reached your limit for free conversation translations.{' '}
              <button
                onClick={() => navigate('/checkout')}
                className="font-medium underline hover:text-blue-600 focus:outline-none"
              >
                Upgrade now
              </button>{' '}
              for unlimited translations.
            </p>
          </div>
        )}
        
        <div className="flex-1 flex flex-col">
          {/* Top half section */}
          <div className="flex-1 p-4 flex flex-col justify-center bg-gray-50 border-b border-gray-200">
            <div 
              ref={topScrollRef}
              className="w-full h-full flex flex-col justify-center items-center text-center"
            >
              {getDisplayText('top') || topTranslation ? (
                <>
                  <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 max-w-md w-full">
                    <p className="text-lg text-gray-800 mb-2 break-words">
                      {getDisplayText('top')}
                      {activeMic === 'top' && isListening && (
                        <span className="inline-block w-0.5 h-5 bg-blue-600 ml-1 animate-pulse">|</span>
                      )}
                    </p>
                    {topTranslation && (
                      <p className="text-base text-gray-600 italic break-words">{topTranslation}</p>
                    )}
                  </div>
                </>
              ) : (
                <div className="text-center p-4 bg-white rounded-lg shadow-sm border border-gray-200 max-w-md w-full">
                  <p className="text-xl text-gray-400">Tap mic to start</p>
                  <p className="text-sm text-gray-400 mt-2">{fromLanguage.name}</p>
                </div>
              )}
            </div>
          </div>

          {/* Bottom half section */}
          <div className="flex-1 p-4 flex flex-col justify-center">
            <div 
              ref={bottomScrollRef}
              className="w-full h-full flex flex-col justify-center items-center text-center"
            >
              {getDisplayText('bottom') || bottomTranslation ? (
                <>
                  <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 max-w-md w-full">
                    <p className="text-lg text-gray-800 mb-2 break-words">
                      {getDisplayText('bottom')}
                      {activeMic === 'bottom' && isListening && (
                        <span className="inline-block w-0.5 h-5 bg-blue-600 ml-1 animate-pulse">|</span>
                      )}
                    </p>
                    {bottomTranslation && (
                      <p className="text-base text-gray-600 italic break-words">{bottomTranslation}</p>
                    )}
                  </div>
                </>
              ) : (
                <div className="text-center p-4 bg-white rounded-lg shadow-sm border border-gray-200 max-w-md w-full">
                  <p className="text-xl text-gray-400">Tap mic to start</p>
                  <p className="text-sm text-gray-400 mt-2">{toLanguage.name}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Bottom control section with updated mic animations */}
        <div className="bg-blue-600 p-4 md:p-6 rounded-t-3xl shadow-lg">
          <div className="flex items-center justify-center space-x-4 mb-6">
            <div className="relative">
              <button 
                onClick={() => setShowFromLanguageSelector(true)}
                className="px-4 py-2 bg-white rounded-lg text-blue-600 font-medium hover:bg-blue-50 transition-colors touch-target flex items-center"
              >
                <span className="max-w-[120px] truncate">{fromLanguage.name}</span>
              </button>
            </div>
            <ArrowLeftRight className="text-white" size={24} />
            <div className="relative">
              <button 
                onClick={() => setShowToLanguageSelector(true)}
                className="px-4 py-2 bg-white rounded-lg text-blue-600 font-medium hover:bg-blue-50 transition-colors touch-target flex items-center"
              >
                <span className="max-w-[120px] truncate">{toLanguage.name}</span>
              </button>
            </div>
          </div>

          <div className="flex justify-around">
            <div className="relative">
              <button
                onClick={() => handleMicClick('top')}
                className={`p-4 rounded-full transition-all duration-200 touch-target ${
                  activeMic === 'top' && isListening
                    ? 'bg-red-500'
                    : 'bg-white hover:bg-blue-50'
                }`}
                aria-label={`Microphone for ${fromLanguage.name}`}
              >
                {activeMic === 'top' && isListening && (
                  <div className="absolute inset-0">
                    <span className="absolute inset-0 rounded-full bg-red-500 animate-ping opacity-75" />
                    <span className="absolute inset-0 rounded-full bg-red-500/70 animate-pulse" />
                  </div>
                )}
                <Mic
                  size={28}
                  className={`relative z-10 ${
                    activeMic === 'top' && isListening 
                      ? 'text-white animate-bounce' 
                      : 'text-blue-600'
                  }`}
                  style={{
                    animation: activeMic === 'top' && isListening ? 'bounce 1s infinite' : 'none'
                  }}
                />
              </button>
              {activeMic === 'top' && isListening && (
                <div className="absolute -inset-2 rounded-full animate-pulse bg-red-500/20" />
              )}
            </div>

            <div className="relative">
              <button
                onClick={() => handleMicClick('bottom')}
                className={`p-4 rounded-full transition-all duration-200 touch-target ${
                  activeMic === 'bottom' && isListening
                    ? 'bg-red-500'
                    : 'bg-white hover:bg-blue-50'
                }`}
                aria-label={`Microphone for ${toLanguage.name}`}
              >
                {activeMic === 'bottom' && isListening && (
                  <div className="absolute inset-0">
                    <span className="absolute inset-0 rounded-full bg-red-500 animate-ping opacity-75" />
                    <span className="absolute inset-0 rounded-full bg-red-500/70 animate-pulse" />
                  </div>
                )}
                <Mic
                  size={28}
                  className={`relative z-10 ${
                    activeMic === 'bottom' && isListening 
                      ? 'text-white animate-bounce' 
                      : 'text-blue-600'
                  }`}
                  style={{
                    animation: activeMic === 'bottom' && isListening ? 'bounce 1s infinite' : 'none'
                  }}
                />
              </button>
              {activeMic === 'bottom' && isListening && (
                <div className="absolute -inset-2 rounded-full animate-pulse bg-red-500/20" />
              )}
            </div>
          </div>
          
          {/* Clear buttons */}
          <div className="flex justify-center mt-4 space-x-4">
            <button
              onClick={() => clearTexts('all')}
              className="px-4 py-2 bg-white/10 text-white rounded-lg text-sm hover:bg-white/20 transition-colors"
            >
              Clear All
            </button>
          </div>
        </div>

        {/* Language Selectors */}
        {showFromLanguageSelector && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl shadow-2xl max-w-md w-full max-h-[80vh] overflow-hidden animate-fade-in">
              <div className="flex items-center justify-between p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold">Select From Language</h2>
                <button 
                  onClick={() => setShowFromLanguageSelector(false)}
                  className="p-2 rounded-full hover:bg-gray-100"
                >
                  <X size={20} className="text-gray-500" />
                </button>
              </div>
              <div className="max-h-[60vh] overflow-y-auto">
                <LanguageSelector
                  title=""
                  selectedLanguage={fromLanguage}
                  onSelectLanguage={handleFromLanguageSelect}
                />
              </div>
            </div>
          </div>
        )}
        
        {showToLanguageSelector && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl shadow-2xl max-w-md w-full max-h-[80vh] overflow-hidden animate-fade-in">
              <div className="flex items-center justify-between p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold">Select To Language</h2>
                <button 
                  onClick={() => setShowToLanguageSelector(false)}
                  className="p-2 rounded-full hover:bg-gray-100"
                >
                  <X size={20} className="text-gray-500" />
                </button>
              </div>
              <div className="max-h-[60vh] overflow-y-auto">
                <LanguageSelector
                  title=""
                  selectedLanguage={toLanguage}
                  onSelectLanguage={handleToLanguageSelect}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ConversationTranslation;