import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * SEO component for dynamic meta tags
 * 
 * @param {Object} props
 * @param {string} props.title - Page title
 * @param {string} props.description - Page description
 * @param {string} props.canonicalUrl - Canonical URL for the page
 * @param {string} props.image - Image URL for social sharing
 * @param {string} props.type - Page type (default: website)
 * @param {Object} props.schema - JSON-LD schema data
 */
function SEO({
  title = 'RealTranslate - Free Translation for Text and Images',
  description = 'Translate over 100 languages for free. RealTranslate makes it easy to translate text and images instantly.',
  canonicalUrl,
  image = '/images/og-image.png',
  type = 'website',
  schema,
}) {
  const siteUrl = 'https://realtranslate.com';
  const fullUrl = canonicalUrl ? `${siteUrl}${canonicalUrl}` : siteUrl;
  const imageUrl = image.startsWith('http') ? image : `${siteUrl}${image}`;

  return (
    <Helmet>
      {/* Basic meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={fullUrl} />

      {/* Open Graph meta tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content="RealTranslate" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />

      {/* JSON-LD structured data */}
      {schema && (
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      )}
    </Helmet>
  );
}

export default SEO; 