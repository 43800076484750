import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { 
  Type, 
  Image, 
  MessageSquare, 
  Menu, 
  UserCircle, 
  BookmarkIcon, 
  X, 
  Store, 
  Smartphone,
  ChevronLeft,
  ChevronRight,
  ChevronDown
} from 'lucide-react';
import Modal from 'react-modal';
import Home from './screens/Home';
import TextTranslation from './screens/TextTranslation';
import ConversationTranslation from './screens/ConversationTranslation';
import SavedTranslations from './screens/SavedTranslationScreen';
import ImageTranslation from './screens/ImageTranslation';
import CheckoutScreen from './screens/CheckoutScreen';
import NotFound from './screens/NotFound';
import AuthComponent from '../src/components/AuthComponent';
import logo from './logo.jpg';
import screenshot1 from './assets/1.png';
import screenshot2 from './assets/2.png';
import screenshot3 from './assets/3.png';
import screenshot4 from './assets/4.png';
import screenshot5 from './assets/5.png';
import { auth, analytics } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { logEvent } from "firebase/analytics";
import { SubscriptionProvider } from './context/SubscriptionContext'; // Ensure correct import path
import SuccessScreen from './screens/SuccessScreen';


Modal.setAppElement('#root');

const appScreenshots = [
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot5
];
// First, update the AppShowcaseModal component
function AppShowcaseModal({ isOpen, onClose }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setCurrentSlide((prev) => (prev === appScreenshots.length - 1 ? 0 : prev + 1));
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isOpen]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === appScreenshots.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? appScreenshots.length - 1 : prev - 1));
  };

  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const storeUrl = isIOS 
    ? "https://apps.apple.com/us/app/realtranslate-ai-translator/id6670330491"
    : "";

  const handleDownloadClick = () => {
    logEvent(analytics, 'app_download_click', {
      platform: isIOS ? 'ios' : 'android',
      source: 'showcase_modal'
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="App Showcase Modal"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <div className="bg-white rounded-3xl shadow-2xl overflow-hidden w-full max-w-sm mx-auto flex flex-col animate-fade-in" style={{ maxHeight: 'calc(100vh - 48px)' }}>
        <div className="relative">
          <div className="absolute top-4 right-4 z-10">
            <button 
              onClick={onClose}
              className="p-2 rounded-full bg-white/80 hover:bg-white transition-colors shadow-md"
            >
              <X size={20} className="text-gray-600" />
            </button>
          </div>
        </div>
        
        <div className="relative flex-1 min-h-0">
          <div className="relative w-full" style={{ height: 'calc(100vh - 230px)' }}>
            {appScreenshots.map((screenshot, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-300 ${
                  index === currentSlide ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <img
                  src={screenshot}
                  alt={`App Screenshot ${index + 1}`}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}

            <button
              onClick={prevSlide}
              className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 rounded-full p-1.5 hover:bg-white transition-colors shadow-lg"
            >
              <ChevronLeft size={20} />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 rounded-full p-1.5 hover:bg-white transition-colors shadow-lg"
            >
              <ChevronRight size={20} />
            </button>

            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
              {appScreenshots.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-1.5 h-1.5 rounded-full transition-colors ${
                    index === currentSlide ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="p-4 bg-white shrink-0">
          <h2 className="text-lg font-bold mb-1">
            Experience RealTranslate at its Best
          </h2>
          <p className="text-sm text-gray-600 mb-3">
            Get instant translations, offline support, and more features on our mobile app.
          </p>
          <a
            href={storeUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleDownloadClick}
            className="block w-full bg-blue-600 text-white py-2.5 px-4 rounded-lg font-medium hover:bg-blue-700 transition-colors text-center text-sm"
          >
            Download Now
          </a>
        </div>
      </div>
    </Modal>
  );
}

function MobileAppBanner() {
  const [isVisible, setIsVisible] = useState(false);
  const [isShowcaseModalOpen, setIsShowcaseModalOpen] = useState(false);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const hasClosedBanner = localStorage.getItem('mobileBannerClosed');
    const hasSeenShowcase = localStorage.getItem('hasSeenAppShowcase');
    
    setIsVisible(isMobile && !hasClosedBanner);
    
    if (isMobile && !hasSeenShowcase) {
      const timer = setTimeout(() => {
        setIsShowcaseModalOpen(true);
        localStorage.setItem('hasSeenAppShowcase', 'true');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, []);

  if (!isVisible) return null;

  const closeBanner = () => {
    localStorage.setItem('mobileBannerClosed', 'true');
    setIsVisible(false);
  };

  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);

  return (
    <>
      <div className="bg-blue-500 text-white px-4 py-2.5">
        <div className="flex items-center justify-between max-w-7xl mx-auto">
          <div className="flex items-center flex-1 min-w-0">
            <Smartphone size={18} className="shrink-0 mr-2" />
            <span className="text-sm font-medium truncate">
              Get the RealTranslate app
            </span>
          </div>
          <div className="flex items-center space-x-2 ml-2">
            {isIOS && (
              <a
                href="https://apps.apple.com/us/app/realtranslate-ai-translator/id6670330491"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-blue-500 px-2.5 py-1 rounded-full text-xs font-medium flex items-center whitespace-nowrap"
              >
                <Store size={12} className="mr-1" />
                App Store
              </a>
            )}
            {isAndroid && (
              <a
                href="https://apps.apple.com/us/app/realtranslate-ai-translator/id6670330491"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-blue-500 px-2.5 py-1 rounded-full text-xs font-medium flex items-center whitespace-nowrap"
              >
                <Store size={12} className="mr-1" />
                Play Store
              </a>
            )}
            <button
              onClick={() => setIsShowcaseModalOpen(true)}
              className="text-white hover:text-gray-100 text-xs underline"
            >
              Preview
            </button>
            <button
              onClick={closeBanner}
              className="text-white hover:text-gray-100 p-1"
              aria-label="Close banner"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      </div>
      
      <AppShowcaseModal
        isOpen={isShowcaseModalOpen}
        onClose={() => setIsShowcaseModalOpen(false)}
      />
    </>
  );
}

function AppContent() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSavedModalOpen, setIsSavedModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser && isSavedModalOpen) {
        setIsSavedModalOpen(false);
        navigate('/saved');
      }
    });

    return () => unsubscribe();
  }, [isSavedModalOpen, navigate]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleSavedClick = () => {
    if (!user) {
      setIsSavedModalOpen(true);
    } else {
      navigate('/saved');
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <MobileAppBanner />
      <nav className="bg-blue-600 shadow-md sticky top-0 z-30">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex-shrink-0 flex items-center">
                <img 
                  src={logo} 
                  alt="RealTranslate Logo" 
                  className="h-8 w-8 mr-2"
                  loading="eager"
                  width="32"
                  height="32"
                />
                <span className="text-xl font-semibold text-white">RealTranslate</span>
              </Link>
            </div>

            <div className="hidden md:flex md:items-center md:space-x-4">
              <NavLink to="/text/en/fr" icon={<Type size={20} />} text="Text" />
              <NavLink to="/image" icon={<Image size={20} />} text="Image" />
              <NavLink to="/conversation" icon={<MessageSquare size={20} />} text="Conversation" />
              
              <button
                onClick={handleSavedClick}
                className="text-white hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium flex items-center"
              >
                <BookmarkIcon size={20} className="mr-1" />
                Saved
              </button>
              
              {user ? (
                <div className="flex items-center ml-4">
                  <img
                    src={user.photoURL}
                    alt={user.displayName}
                    className="h-8 w-8 rounded-full border-2 border-white"
                    loading="lazy"
                  />
                  <div className="ml-2 relative group">
                    <button className="text-white hover:text-gray-200 flex items-center">
                      <span className="mr-1 max-w-[100px] truncate hidden sm:inline">{user.displayName}</span>
                      <ChevronDown size={16} />
                    </button>
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50 hidden group-hover:block">
                      <button
                        onClick={handleSignOut}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Sign Out
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => navigate('/checkout')}
                  className="ml-4 bg-white text-blue-600 hover:bg-blue-50 px-4 py-2 rounded-md text-sm font-medium"
                >
                  Sign In
                </button>
              )}
            </div>

            <div className="flex items-center md:hidden">
              {user && (
                <img
                  src={user.photoURL}
                  alt={user.displayName}
                  className="h-8 w-8 rounded-full border-2 border-white mr-2"
                  loading="lazy"
                />
              )}
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-blue-700 focus:outline-none"
                aria-expanded={isMenuOpen}
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>

        <div
          ref={menuRef}
          className={`${
            isMenuOpen ? 'block animate-fade-in-mobile' : 'hidden'
          } md:hidden bg-blue-700`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <NavLink to="/text/en/fr" icon={<Type size={20} />} text="Text" mobile={true} />
            <NavLink to="/image" icon={<Image size={20} />} text="Image" mobile={true} />
            <NavLink to="/conversation" icon={<MessageSquare size={20} />} text="Conversation" mobile={true} />
            
            <button
              onClick={handleSavedClick}
              className="w-full text-left text-white hover:bg-blue-800 block px-3 py-2 rounded-md text-base font-medium flex items-center"
            >
              <BookmarkIcon size={20} className="mr-2" />
              Saved
            </button>
            
            {user ? (
              <button
                onClick={handleSignOut}
                className="w-full text-left text-white hover:bg-blue-800 block px-3 py-2 rounded-md text-base font-medium flex items-center"
              >
                <UserCircle size={20} className="mr-2" />
                Sign Out
              </button>
            ) : (
              <button
                onClick={() => navigate('/checkout')}
                className="w-full text-left text-white hover:bg-blue-800 block px-3 py-2 rounded-md text-base font-medium flex items-center"
              >
                <UserCircle size={20} className="mr-2" />
                Sign In
              </button>
            )}
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/text" element={<TextTranslation />} />
          <Route path="/text/:source/:target" element={<TextTranslation />} />
          <Route path="/translate/:source-:target" element={<TranslationRedirect />} />
          <Route path="/image" element={<ImageTranslation />} />
          <Route path="/conversation" element={<ConversationTranslation />} />
          <Route path="/saved" element={<SavedTranslations />} />
          <Route path="/checkout" element={<CheckoutScreen />} />
          <Route path='/success' element={<SuccessScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>

      <Modal
        isOpen={isSavedModalOpen}
        onRequestClose={() => setIsSavedModalOpen(false)}
        contentLabel="Saved Translations Modal"
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
      >
        <div className="bg-white rounded-3xl shadow-2xl overflow-hidden max-w-md w-full mx-auto animate-fade-in">
          <div className="relative">
            <div className="absolute top-4 right-4 z-10">
              <button 
                onClick={() => setIsSavedModalOpen(false)}
                className="p-2 rounded-full bg-white/80 hover:bg-white transition-colors shadow-md"
              >
                <X size={20} className="text-gray-600" />
              </button>
            </div>
            
            <div className="h-32 bg-gradient-to-r from-blue-500 to-indigo-600 flex items-center justify-center">
              <div className="bg-white rounded-full p-4 shadow-lg">
                <BookmarkIcon size={32} className="text-blue-600" />
              </div>
            </div>
          </div>
          
          <div className="px-8 py-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-2">Access Saved Translations</h2>
              <p className="text-gray-500">Sign in to access your saved translations</p>
            </div>
            
            {!user && <AuthComponent />}
            
            {user && (
              <button
                onClick={() => setIsSavedModalOpen(false)}
                className="w-full py-3 px-4 bg-gray-100 text-gray-700 rounded-xl font-medium
                  transition-all duration-300 hover:bg-gray-200 hover:shadow-md mt-4"
              >
                Close
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

// Main App component that provides the Router context
function App() {
  return (
    <Router>
      <SubscriptionProvider>
        <AnalyticsTracker />
        <AppContent />
      </SubscriptionProvider>
    </Router>
  );
}

function TranslationRedirect() {
  const location = useLocation();
  const path = location.pathname;
  const match = path.match(/\/translate\/(.+)-(.+)/);
  
  if (match) {
    const [, source, target] = match;
    return <Navigate to={`/text/${source}/${target}`} replace />;
  }
  
  return <NotFound />;
}

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = document.title;
    const pageUrl = window.location.href;

    logEvent(analytics, 'page_view', {
      page_title: pageTitle,
      page_location: pageUrl,
      page_path: location.pathname,
    });
  }, [location]);

  return null;
}

function NavLink({ to, icon, text, mobile }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(to);
  
  return (
    <Link
      to={to}
      className={`${
        isActive
          ? mobile
            ? 'bg-blue-800 text-white'
            : 'bg-blue-700 text-white'
          : mobile
            ? 'text-white hover:bg-blue-800'
            : 'text-white hover:bg-blue-700'
      } ${
        mobile
          ? 'block px-3 py-2 rounded-md text-base font-medium flex items-center'
          : 'px-3 py-2 rounded-md text-sm font-medium flex items-center'
      }`}
    >
      {icon && <span className={mobile ? 'mr-2' : 'mr-1'}>{icon}</span>}
      {text}
    </Link>
  );
}

export default App;